<template>
  <b-form @submit.stop.prevent>
    <div class="d-flex flex-row flex-wrap">
      <!-- <div class="col-lg-4 col-12 px-2 mb-2">
        <label class="font-weight-bold" for="rank">ยศ</label>
        <v-select
          id="rank"
          v-model="rank"
          :options="ranks"
          :disabled="disabled"
          :loading="isFetchingRank"
        ></v-select>

        <small
          v-if="
            invalid &&
            validation.formTeacher.mRankId &&
            !validation.formTeacher.mRankId.required
          "
          class="text-danger"
        >
          กรุณาเลือกยศ
        </small>
      </div> -->

      <div class="col-lg-6 col-12 px-2 mb-2">
        <label class="font-weight-bold" for="firstName">ชื่อ</label>

        <b-form-input
          id="firstName"
          :state="invalid ? validation.formTeacher.first_name.required : null"
          type="text"
          disabled
          placeholder="เด็กชายคมศักดิ์"
        ></b-form-input>

        <b-form-invalid-feedback id="firstName-feedback">
          กรุณากรอกชื่อ
        </b-form-invalid-feedback>
      </div>

      <div class="col-lg-6 col-12 px-2 mb-2">
        <label class="font-weight-bold" for="lastName">นามสกุล</label>

        <b-form-input
          id="lastName"
          :state="invalid ? validation.formTeacher.last_name.required : null"
          type="text"
          disabled
          placeholder="วงศ์งาม"
        ></b-form-input>

        <b-form-invalid-feedback id="lastName-feedback">
          กรุณากรอกนามสกุล
        </b-form-invalid-feedback>
      </div>

      <div class="col-lg-4 col-12 px-2 mb-2">
        <label class="font-weight-bold" for="positionName">ระดับชั้น</label>
        <input
          id="positionName"
          class="form-control"
          type="text"
          disabled
          placeholder="ป.6"
        />
      </div>

      <div class="col-lg-4 col-12 px-2 mb-2">
        <label class="font-weight-bold" for="birthDate">โรงเรียน</label>
        <datepicker
          ref="datepicker"
          id="birthDate"
          input-class="form-control"
          required
          disabled
          :language="th"
          :format="formatBuddhistBirthDate"
          placeholder="โรงเรียนบางพลี"
        ></datepicker>

        <small
          v-if="
            invalid &&
            validation.formTeacher.birth_date &&
            !validation.formTeacher.birth_date.required
          "
          class="text-danger"
        >
          กรุณาเลือกวัน/เดือน/ปีเกิด
        </small>
      </div>

      <div class="col-lg-4 col-12 px-2 mb-2">
        <label class="font-weight-bold" for="retireYear">วัน/เดือน/ปีเกิด</label>

        <b-form-input
          id="retireYear"
          type="text"
          disabled
          placeholder="20/05/2540"
          
        ></b-form-input>
      </div>

      <div class="col-lg-4 col-12 px-2 mb-2">
        <label class="font-weight-bold" for="idCard">เลขบัตรประชาชน</label>

        <b-form-input
          id="idCard"
          :state="
            invalid
              ? validation.formTeacher.id_card &&
                validation.formTeacher.id_card.required &&
                validation.formTeacher.id_card.valid
              : null
          "
          type="text"
          disabled
          required
          v-mask="'#-####-#####-##-#'"
          placeholder="1110100149838"
        ></b-form-input>

        <b-form-invalid-feedback id="idCard-feedback">
          <span
            v-if="
              validation.formTeacher.id_card &&
              !validation.formTeacher.id_card.required
            "
          >
            กรุณากรอกเลขบัตรประชาชน
          </span>

          <span
            v-else-if="
              validation.formTeacher.id_card &&
              !validation.formTeacher.id_card.valid
            "
          >
            เลขบัตรประชาชนไม่ถูกต้อง
          </span>
          <!-- <span>เลขบัตรประชาชนไม่ถูกต้อง</span> -->
        </b-form-invalid-feedback>
      </div>

      <div class="col-lg-4 col-12 px-2 mb-2">
        <label class="font-weight-bold" for="phoneNumber">เบอร์โทรศัพท์</label>

        <b-form-input
          id="phoneNumber"
          :state="
            invalid
              ? validation.formTeacher.phone_number &&
                validation.formTeacher.phone_number.required &&
                validation.formTeacher.phone_number.minLength &&
                validation.formTeacher.phone_number.maxLength
              : null
          "
          type="tel"
          placeholder="099-032-2123"
          disabled
          v-mask="'0##-###-####'"
        ></b-form-input>

        <b-form-invalid-feedback id="phoneNumber-feedback">
          <span
            v-if="
              validation.formTeacher.phone_number &&
              !validation.formTeacher.phone_number.required
            "
          >
            กรุณากรอกเบอร์โทรศัพท์
          </span>

          <span
            v-else-if="
              validation.formTeacher.phone_number &&
              (!validation.formTeacher.phone_number.minLength ||
                !validation.formTeacher.phone_number.maxLength)
            "
          >
            เบอร์โทรศัพท์ไม่ถูกต้อง
          </span>
        </b-form-invalid-feedback>
      </div>

      <div class="col-lg-4 col-12 px-2 mb-2">
        <label class="font-weight-bold" for="email">อีเมล</label>

        <b-form-input
          id="email"
          :state="
            invalid
              ? validation.formTeacher.email &&
                validation.formTeacher.email.required &&
                validation.formTeacher.email.email
              : null
          "
          type="email"
          disabled
          placeholder="student1@gmail.com"
        ></b-form-input>

        <b-form-invalid-feedback id="phoneNumber-feedback">
          <span
            v-if="
              validation.formTeacher.email &&
              !validation.formTeacher.email.required
            "
          >
            กรุณากรอกอีเมล
          </span>

          <span
            v-else-if="
              validation.formTeacher.email &&
              !validation.formTeacher.email.email
            "
          >
            อีเมลไม่ถูกต้อง
          </span>
        </b-form-invalid-feedback>
      </div>

      <div class="col-lg-4 col-12 px-2 mb-2">
        <label class="font-weight-bold" for="email">แก้ไขรหัสผ่าน</label>

        <b-form-input
          id="email"
          :state="
            invalid
              ? validation.formTeacher.email &&
                validation.formTeacher.email.required &&
                validation.formTeacher.email.email
              : null
          "
          type="email"
          placeholder="ระบุรหัสผ่านใหม่"
        ></b-form-input>

        <b-form-invalid-feedback id="phoneNumber-feedback">
          <span
            v-if="
              validation.formTeacher.email &&
              !validation.formTeacher.email.required
            "
          >
            กรุณากรอกอีเมล
          </span>

          <span
            v-else-if="
              validation.formTeacher.email &&
              !validation.formTeacher.email.email
            "
          >
            อีเมลไม่ถูกต้อง
          </span>
        </b-form-invalid-feedback>
      </div>

      <div
        v-if="authUser.isAdminType && !authUser.isExecutive"
        class="col-lg-12 col-12 px-2 mb-2"
      >
        <label class="font-weight-bold" for="userStatus">สถานะ</label>

        <v-select
          id="userStatus"
          v-model="form.user_status"
          :options="userStatuses"
          :disabled="disabled"
        ></v-select>

        <small
          v-if="
            invalid &&
            validation.formTeacher.user_status &&
            !validation.formTeacher.user_status.required
          "
          class="text-danger"
        >
          กรุณาเลือกสถานะ
        </small>
      </div>

      

      <!-- <div
        v-if="editData && editData.id && authUser.isSuperAdmin"
        class="col-lg-2 col-12 px-2 my-2 d-flex align-items-end"
      >
        <b-form-checkbox
          v-model="isAdmin"
          name="isAdmin"
          switch
          :disabled="disabled || disabledInput.isAdmin"
        >
          สิทธิ์ผู้ดูแลระบบ
        </b-form-checkbox>
      </div>

      <div v-if="isAdmin" class="col-lg-4 col-12 px-2 mb-2">
        <label class="font-weight-bold" for="userStatus"
          >ประเภทสิทธิ์ผู้ดูแลระบบ</label
        >

        <v-select
          id="userStatus"
          v-model="adminType"
          :options="adminTypes"
          :disabled="!isAdmin || disabled || disabledInput.adminType"
        ></v-select>

        <small
          v-if="
            invalid &&
            validation.formTeacher.admin_type &&
            !validation.formTeacher.admin_type.required
          "
          class="text-danger"
        >
          กรุณาเลือกประเภทสิทธิ์ผู้ดูแลระบบ
        </small>
      </div> -->

      <!-- <div class="col-12 px-2 my-2">
        <label class="font-weight-bold" for="address"
          >ที่อยู่ปัจจุบันที่สามารถติดต่อได้</label
        >

        <b-form-textarea
          id="address"
          :state="null"
          :disabled="disabled"
          v-model="form.address"
          rows="3"
          max-rows="6"
        ></b-form-textarea>
      </div>

      <div class="col-12 px-2 my-2">
        <label class="font-weight-bold" for="addressDocument"
          >ที่อยู่สำหรับจัดส่งเอกสาร</label
        >

        <b-form-textarea
          id="addressDocument"
          :state="null"
          :disabled="disabled"
          v-model="form.address_document"
          rows="3"
          max-rows="6"
        ></b-form-textarea>
      </div> -->

      <!-- <div v-if="isClass" class="col-lg-2 col-12 px-2 mb-2">
        <label class="font-weight-bold" for="box5"
          >จำนวนนักเรียนจริง</label
        >
        <input
          class="form-control custom__height"
          type="number"
          :min="0"
          :disabled="disabled"
          v-model.number="form.actual_student"
        />
      </div> -->
    </div>
  </b-form>
</template>

<script>
import {
  toBuddhistCalendar,
  formatBuddhistBirthDate,
  calculateRetireYear,
} from "../../helpers/datepicker-helper";
import formatDateMixin from "../../mixins/formatDateMixin";
import vSelect from "vue-select";
import { th } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";
import { MRank, Auth } from "../../models";

toBuddhistCalendar(Datepicker);

// const currentYear = new Date().getFullYear() + 543;

export default {
  mixins: [formatDateMixin],

  props: {
    formData: Object,
    editData: {
      type: Object,
      default: () => {},
    },
    isLesson: {
      type: Boolean,
      default: false,
    },
    isClass: {
      type: Boolean,
      default: false,
    },
    isTeacher: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    validation: {
      type: Object,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    disabledInput: {
      type: Object,
      default: () => ({}),
    },
    userType: {
      type: String,
      default: "user",
    },
    forceUpdate: {
      type: Boolean,
      default: false,
    },
  },

  model: {
    prop: "formData",
    event: "change",
  },

  components: {
    Datepicker,

    "v-select": vSelect,
  },

  data() {
    return {
      th,
      isFetchingRank: false,
      rank: null,
      adminType: null,
      birthDate: this.editData.bd_date
        ? new Date(this.editData.bd_date).setFullYear(
            new Date(this.editData.bd_date).getFullYear() - 543
          )
        : null,
      form: {
        address: this.editData.address || "",
        address_document: this.editData.address_document || "",
        first_name: this.editData.first_name || "",
        last_name: this.editData.last_name || "",
        bd_date: this.editData.bd_date || "",
        birth_date: this.editData.birth_date || "",
        retire_year: this.editData.birth_date
          ? calculateRetireYear(this.editData.birth_date, true)
          : "",
        id_card: this.editData.id_card || "",
        phone_number: this.editData.phone_number || "",
        email: this.editData.email || null,
        mRankId: this.editData.mRankId || null,
        user_type: this.editData.user_type
          ? this.editData.user_type
          : this.userType
          ? this.userType
          : null,
        admin_type: this.editData.admin_type || null,
        user_status: this.editData.user_status || "เข้าสอน",
        position_name: this.editData.position_name || null,
        forceUpdate: this.forceUpdate,
      },
    };
  },

  watch: {
    form: {
      deep: true,
      immediate: true,
      handler: "syncData",
    },

    adminType: {
      handler: "onAdminTypeChanged",
    },

    rank: {
      handler: "onRankChanged",
    },

    birthDate: {
      handler: "onBirthDateChanged",
    },
  },

  computed: {
    authUser() {
      return Auth.user();
    },

    userStatuses() {
      return ["เข้าสอน", "ไม่เข้าสอน"];
    },

    adminTypes() {
      let adminTypes = [];

      if (this.authUser.isSuperAdmin || this.authUser.isExecutive) {
        adminTypes.push({ id: "EXECUTIVE", label: "ผู้บริหาร" });
      }

      return [
        ...adminTypes,
        { id: "ADMIN_ALL", label: "ทั่วประเทศ" },
        { id: "ADMIN_HEADQUARTER", label: "ระดับภาค" },
        { id: "ADMIN_DIVISION", label: "ระดับจังหวัด" },
        { id: "ADMIN_STATION", label: "ระดับสถานี" },
      ];
    },

    ranks() {
      return MRank.all().map((record) => ({
        id: record.id,
        label: record.m_rank_name,
      }));
    },

    isAdmin: {
      get() {
        return (
          this.form.user_type === "ADMIN" ||
          this.form.user_type === "SUPER_ADMIN"
        );
      },

      set(v) {
        this.$set(this.form, "user_type", v ? "ADMIN" : "user");

        return this;
      },
    },
  },

  methods: {
    formatBuddhistBirthDate,

    async init() {
      this.isFetchingRank = true;

      try {
        await MRank.api().findAll();

        if (this.editData) {
          const { admin_type, mRankId } = this.editData;

          const mRank = MRank.find(mRankId);

          if (mRank) {
            this.rank = {
              id: mRank.id,
              label: mRank.m_rank_name,
            };
          }

          if (admin_type) {
            const targetAdminType = this.adminTypes.find(
              ({ id }) => admin_type == id
            );

            if (targetAdminType) {
              this.adminType = { ...targetAdminType };
            }
          }
        }
      } catch (error) {
        console.error(error);
        this.$toast.error("ไม่สามารถดึงข้อมูลยศได้ กรุณาลองใหม่อีกครั้ง");
      } finally {
        this.isFetchingRank = false;
      }
    },

    onAdminTypeChanged(adminType = null) {
      let id = null;

      if (adminType && adminType.id) {
        id = adminType.id;
      }

      this.$set(this.form, "admin_type", id);
    },

    onRankChanged(rank = null) {
      let id = null;

      if (rank && rank.id) {
        id = rank.id;
      }

      this.$set(this.form, "mRankId", id);
    },

    onBirthDateChanged(date) {
      const dObj = new Date(date.getTime());

      const buddhistBirthDate = formatBuddhistBirthDate(dObj);

      this.$set(this.form, "birth_date", buddhistBirthDate);

      dObj.setFullYear(dObj.getFullYear() + 543);
      dObj.setHours(0);
      dObj.setMinutes(0);
      dObj.setSeconds(0);

      this.$set(this.form, "retire_year", calculateRetireYear(dObj));
      this.$set(this.form, "bd_date", dObj.toISOString());
    },

    syncData(v) {
      this.$emit("change", v);
    },
  },

  mounted() {
    this.init();
  },
};
</script>
